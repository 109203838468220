<template>
  <div id="app">
    <transition-fade>
      <modal v-if="$store.state.loginRequired" @close="$store.commit('setLoginRequired', false)">
        <template v-slot:header>
          {{ $tTrim("login.loginRequired") }}
        </template>
        <template v-slot:body>
          {{ $tTrim("login.loginToBet") }}
        </template>
      </modal>
    </transition-fade>

    <slot />

    <footer>
      <div>
        <template v-if="playerID">
          <div v-if="!hidePlayerID" class="inline-block">{{ playerID }}</div>
          <div v-if="!hideBalance" class="inline-block">({{ balance }})</div>
        </template>
        <div class="loading-ellipsis" v-if="!playerID && $store.state.account.isUpdating">
          {{ $tTrim("loading.account") }}
        </div>
      </div>
      <div class="flex flex-col items-center">
        <play-time />
        <nuxt-link v-if="!hideRules" :to="gameRulesLink">{{ $tTrim("rules") }}</nuxt-link>
      </div>
      <div>{{ buildInfo }}</div>
    </footer>

    <a class="home-btn__control" :href="$store.state.home.homeURL" v-if="$store.state.home.homeURL">
      <vue-feather type="home" />
    </a>
  </div>
</template>

<style lang="scss">
#app {
  @apply flex flex-col;
  min-height: 100vh;
  width: 100%;

  & > div {
    @apply flex-grow;
  }

  & > footer {
    @apply flex justify-between;
    padding: $spacing $spacing 60px $spacing;
    background: VarsToHSL(--ttbw-app-footer-background-colour);
  }
}

.loading-ellipsis:after {
  // ensure animation
  overflow: hidden;
  display: inline-block;

  // align periods
  vertical-align: bottom;

  // set up initial condition for the animation
  width: 0px;

  // ascii code for ellipsis
  content: "\2026";

  // run the animation, edit by changing steps and width
  animation: ellipsis steps(4, end) 900ms infinite;
}

// edit the animation by changing steps and width
@keyframes ellipsis {
  to {
    width: 1.25em;
  }
}

// This should be split out instead of duplicating the betslip button
.home-btn {
  &__control {
    @apply flex items-center justify-center shadow-md;
    position: fixed;
    bottom: $spacing;
    left: $spacing;
    width: 60px;
    height: 60px;
    background: VarsToHSL(--ttbw-betslip-background-colour);
    border-radius: 50%;
    border: 1px solid VarsToHSL(--ttbw-betslip-text-colour);
    color: VarsToHSL(--ttbw-betslip-text-colour);

    &:hover {
      background: hsl(
        var(--ttbw-betslip-background-colour-h),
        var(--ttbw-betslip-background-colour-s),
        calc(var(--ttbw-betslip-background-colour-l) - #{$darkness-3})
      );
    }

    @screen lg {
      display: none;
    }
  }
}
</style>

<script>
export default {
  setup() {
    const { public: config } = useRuntimeConfig();
    return {
      buildInfo: `${config.app_version} (#${config.git_hash || "<unknown>"})`,
    };
  },
  head() {
    return {
      title: this.productCode,
    };
  },
  computed: {
    productCode() {
      return this.$tTrim(`product.${this.$store.state.provider.product.productCode}`, { missingTrim: "product." });
    },

    gameRulesLink() {
      const { rulesPath } = useRules();
      console.log(`RulesPath: ${rulesPath}`);
      return rulesPath;
    },

    playerID() {
      return (
        this.$store.state.provider.playerName ||
        this.$store.state.provider.playerID ||
        this.$store.state.account.custRef
      );
    },
    balance() {
      return new Intl.NumberFormat(this.$store.state.i18n.locale, {
        style: "currency",
        currency: this.$store.state.currency.code,
      }).format(this.$store.state.account.balance);
    },
    hidePlayerID() {
      return this.$config.hide_player_id;
    },
    hideBalance() {
      return this.$config.hide_balance;
    },
    hideRules() {
      return this.$config.hide_rules;
    },
  },

  beforeMount() {
    document.getElementsByTagName("html")[0].setAttribute("theme", this.$store.state.provider.theme);
  },

  watch: {
    "$store.state.provider.theme"(val) {
      console.log("Theme changed: " + val);
      document.getElementsByTagName("html")[0].setAttribute("theme", val);
    },
  },
};
</script>
